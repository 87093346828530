
// Vendor bundles (non-Bootstrap)
require('jquery');
require('base-64');
require('d3');
require('angular');
require('angular-cookies');
require('angular-ui-router');
require('angular-animate');
require('angular-messages');
require('bootstrap-loader');
require('angular-ui-bootstrap');
require('font-awesome/css/font-awesome.min.css');
require('angular-ui-grid/ui-grid.min.css');
require('angular-ui-grid/ui-grid.min.js');
require('ng-file-upload');
